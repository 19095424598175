function launch_toast() {
  // var x = document.getElementById("toast");
  // if (x){
  //   x.className = "show";
  //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
  // }
  alert("dale")
}

function create_toast(msg, type){
  $('body').prepend(`<div id="toast"><div id=${type}>${msg}</div></div>`);
}