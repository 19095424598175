// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap");
require("datatables.net-dt")
require("packs/datatables")
require("packs/toasts")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery'
import "@fortawesome/fontawesome-free/js/all"
import 'select2'
import 'select2/dist/css/select2.css'
import 'jquery-mask-plugin';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

window.addEventListener('DOMContentLoaded', () => {
  $('.select2').select2();
})

jQuery(function() { 
  if (!$.fn.dataTable.isDataTable("table[id^=dttb-]")) {
    $("table[id^=dttb-]").DataTable({
      responsive: true
    });
  }

  function imageIsLoaded(e) {
    $('#newPicturesContainer').append('<img class="new-imovel-img img-responsive" id='+e.target.name+' src='+e.target.result+' alt="">');
  };

  function readURL(input) {
    if (input.files && input.files[0]) {
      for (var i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        reader.onload = imageIsLoaded;
        reader.readAsDataURL(input.files[i]);
      }
    }
  }

  // $("#fileImage").on("change", function() { 
  //   $('#newPicturesContainer').empty();
  //   readURL(this);
  // });

  function removeStorage(image) {
    const id = String(image.attr("id"));
    const file_id = id.split('-')[1];
    const files_to_remove = $('.filesToRemove').val();
    if (files_to_remove != '') $('.filesToRemove').val(`${files_to_remove},${file_id}`);
    else $('.filesToRemove').val(file_id);
    image.hide();
  }

  $(".imovel-img").on("click", function() { 
    removeStorage($(this));
  });

  var maskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  };

  var options = { onKeyPress: 
    function(val, e, field, options) {
      field.mask(maskBehavior.apply({}, arguments), options);
    }
  };

  $('.telefone').mask(maskBehavior, options);
  $('.rg').mask('99.999.999-9');
  $('.cpf').mask('999.999.999-99');
  $('.card_number').mask('9999999999999999'); 
  $('.cnpj').mask('00.000.000/0000-00');
  $('.card_expiration').mask('99/99');
  $('.cep').mask('99999-999');
  $('.dinheiro').mask('000.000.000.000.000,00', {reverse: true});

  $("#cep").on("change", function() {
    var cep = $("#cep").val().replace(/\D/g, '');

    if (cep != "") {
      var validacep = /^[0-9]{8}$/;
      if(validacep.test(cep)) {
        $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados) {
          if (!("erro" in dados)) {
            $("#rua").val(dados.logradouro);
            $("#bairro").val(dados.bairro);
            $("#states").val(dados.uf);
            $("#cities").val(dados.localidade);
            $('#states').trigger('change');
          } else {
            alert("CEP não encontrado.");
          }
        });
      }
    }
  });

  $("#states").on("change", function() {
    const val = $("#cities").val();
    var selectedState = $(this).children("option:selected").val();
    $.ajax({
      type: 'GET',
      url: '/enderecos/cidades/'+selectedState,
      success: function (response) {
        $("#cities").empty();
        $("#cities").append(`<option value="">Selecione a cidade</option>`);
        response.forEach(city => {
          $("#cities").append(`<option value="${city}">${city}</option>`);
        });
        $("#cities").val(val);
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
    });
  });

  $("#cities").on("change", function() {
    const val = $("#comunidades").val();
    var selected = $(this).children("option:selected").val();
    $.ajax({
      type: 'GET',
      url: '/enderecos/comunidades/'+selected+'.json',
      success: function (response) {
        $("#comunidades").empty();
        $("#comunidades").append(`<option value="">Selecione a comunidade</option>`)
        $("#comunidades").append(`<option value="0">Não encontrei minha comunidade</option>`)
        response.forEach(c => {
          $("#comunidades").append(`<option value=${c.id}>${c.nome}</option>`) 
        });
        $("#comunidades").val(val);
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
    });
  });

  $("#cidades").on("change", function() {
    const val = $("#comunidades").val();
    var selected = $(this).children("option:selected").val();
    $.ajax({
      type: 'GET',
      url: '/enderecos/comunidades/'+selected+'.json',
      success: function (response) {
        $("#comunidades").empty();
        $("#comunidades").append(`<option value="">Selecione a comunidade</option>`);
        response.forEach(c => {
          $("#comunidades").append(`<option value=${c.id}>${c.nome}</option>`) 
        });
        $("#comunidades").val(val);
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
    });
  });

  function verifyAddress(){
    const estado = $("#states").val();
    const cidade = $("#cities").val();
    const comunidade_id = $("#comunidades").val();
    const comunidade = $("#comunidade").val();
    if (comunidade_id != '' || comunidade != '') {
      $.ajax({
        url: "<%= verifica_endereco_anunciante_imoveis_path(format: :js) %>",
        method: "GET",
        data: {estado, cidade, comunidade_id, comunidade},
        dataType: "json",
        success: function(resp) {
          if (resp) {
            if (resp.disponivel) console.log('Disponivel');
            else console.log('Nao disponivel');
          }
        },
        error: function(resp) {
          console.log('Erro: ', resp)
        }
      });
    } else alert("Preencha a comunidade!");
  }

  $("#states").on("click", function() {
    // verifyAddress();
  });
});